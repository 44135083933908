import React from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

export default function Recoder() {
    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );
    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.controls = true;
  
        document.body.appendChild(audio);
    };
    return (
        <div>
            {/* <div>

                <button onClick={recorderControls.stopRecording}>Stop recording</button>
                <button onClick={recorderControls.startRecording}>Start</button>
            </div> */}
            {/* <AudioRecorder
                downloadFileExtension="mp3"
                showVisualizer={true}
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
            /> */}
            {/* <AudioRecorder
                // onRecordingComplete={addAudioElement}
                recorderControls={recorderControls}
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                }}
                downloadOnSavePress={true}
                downloadFileExtension={'mp3'}
            /> */}

            <div className="tester">
                <AudioRecorder
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    // downloadOnSavePress={true}
                    downloadFileExtension="mp3"
                    showVisualizer={true}
                />
                <br />
                {/* <button onClick={recorderControls.stopRecording}>Stop recording</button> */}
                <br />
            </div>
        </div>
    )
}
