import {
    TeamOutlined,
    InboxOutlined,
    AppstoreOutlined,
    ClockCircleOutlined,
    HomeOutlined,
    SettingOutlined,
    UserSwitchOutlined,
    UsergroupAddOutlined,
    AreaChartOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Badge, Button } from "antd";

export const UserSideBar = (
    navigate,
    FiltredUers,
    setselectedKey,
    isReadData,
    setOpenUserChat,
    setSelectedUserChat,
    unSeenChat,
    visibleUsers,
    maxVisibleUsers,
    messagesData
) => [
        {
            key: "/",
            icon: <HomeOutlined />,
            label: "Home",
            onClick: () => {
                navigate("/");
                setselectedKey("/");
            },
        },
        {
            key: "/analytics",
            icon: <AreaChartOutlined />,
            label: "Analytics",
            onClick: () => {
                setselectedKey("/analytics");
                navigate("/analytics");
            },
        },
        {
            key: "/visitors",
            icon: <UserSwitchOutlined />,
            label: "Visitors",
            onClick: () => {
                setselectedKey("/visitors");
                navigate("/visitors");
            },
        },
        {
            key: "/history",
            icon: <ClockCircleOutlined />,
            label: "History",
            onClick: () => {
                setselectedKey("/history");
                navigate("/history");
            },
        },
        {
            key: "/brands",
            icon: <AppstoreOutlined />,
            label: "Brands",
            onClick: () => {
                setselectedKey("/brands");
                navigate("/brands");
            },
        },
        {
            itemIcon:
                isReadData?.length > 0 ? (
                    <Badge
                        size={"small"}
                        count={isReadData?.length > 0 ? isReadData?.length : 0}
                        showZero
                        color="#faad14"
                    />
                ) : (
                    ""
                ),
            key: "/tickets",
            icon: (
                <>
                    <InboxOutlined />{" "}
                </>
            ),
            label: `Tickets`,
            onClick: () => {
                navigate("/tickets");
                setselectedKey("/tickets");
            },
        },
        {
            itemIcon:
                messagesData?.length > 0 ? (
                    <Badge
                        size={"small"}
                        count={messagesData?.length > 0 ? messagesData?.length : 0}
                        showZero
                        color="#faad14"
                    />
                ) : (
                    ""
                ),
            key: "/users",
            icon: <><TeamOutlined />{" "} </>,
            label: "Users",
            onClick: () => {
                setselectedKey("/users");
                navigate("/users");
            },
        },
        {
            key: "/settings",
            icon: <SettingOutlined />,
            label: "Settings",
            children: [
                {
                    key: "/shortcuts",
                    label: "Shortcuts",
                    onClick: () => {
                        setselectedKey("/shortcuts");
                        navigate("/shortcuts");
                    },
                },
                {
                    key: "/widget",
                    label: "Widget",
                    onClick: () => {
                        setselectedKey("/widget");
                        navigate("/widget");
                    },
                },
                // {
                //     key: "/email-settings",
                //     label: "Email Setting",
                //     onClick: () => {
                //         setselectedKey("/email-settings");
                //         navigate("/email-settings");
                //     },
                // },
                {
                    key: "/banned",
                    label: "Banned",
                    onClick: () => {
                        setselectedKey("/banned");
                        navigate("/banned");
                    },
                },
                {
                    key: "/personal",
                    label: "Personal",
                    onClick: () => {
                        setselectedKey("/personal");
                        navigate("/personal");
                    },
                },
                {
                  key: "/triggers",
                  label: "Triggers",
                  onClick: () => {
                    setselectedKey("/triggers");
                    navigate("/triggers");
                  },
                },
                {
                    key: "/departments",
                    label: "Departments",
                    onClick: () => {
                        setselectedKey("/departments");
                        navigate("/departments");
                    },
                },
                // {
                //     key: "/agent-roles",
                //     label: "Roles",
                //     onClick: () => {
                //         setselectedKey("/agent-roles");
                //         navigate("/agent-roles");
                //     },
                // },
            ],
        },
        {
            key: "14",
            icon: <UsergroupAddOutlined />,
            label: "My Team",
            children: visibleUsers?.map((i, index) => {
                let finduser = unSeenChat?.find((x) => x?.userID === i?._id);
                let offlineMessages = messagesData?.filter((x) => x?.sender === i?._id);
                return (
                    i?.sudoName != "superadmin" && {
                        key: index + 15,
                        label: `${i?.sudoName}`,
                        icon:
                            i?.status == 22 ? (
                                <Badge
                                    count={offlineMessages?.length || finduser?.value}
                                    size={"small"}
                                    showZero
                                    color="green"
                                />
                            ) : i?.status == 23 ? (
                                <Badge
                                    count={offlineMessages?.length || finduser?.value}
                                    size={"small"}
                                    showZero
                                    color="orange"
                                />
                            ) : (
                                <Badge
                                    count={offlineMessages?.length || finduser?.value}
                                    size={"small"}
                                    showZero
                                    color="gray"
                                />
                            ),
                        onClick: () => {
                            setOpenUserChat(true);
                            setSelectedUserChat(i);
                        },
                    }
                )
            }),
        },

        FiltredUers?.length > maxVisibleUsers && {
            key: "15",
            icon: <UserOutlined />,
            label: `(${FiltredUers.length - visibleUsers?.length}) View All`,
            onClick: () => {
                setselectedKey("/users");
                navigate("/users");
            },
        },

        
    ];
