import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Badge, Form, Input, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_Brands_By_Id,
  Get_All_Brands,
  Add_Brand,
  Brand_Status,
  Update_Brand
} from "../../redux/Brands/BrandsActions";
import Recoder from "../../components/audioRecoder/recoder";

const Brands = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const allData = useSelector((state) => state.Brands.data);
  const isLoading = useSelector((state) => state.Brands.isLoading);
  const isAddLoading = useSelector((state) => state.Brands.isAddLoading);
  const usertoken = useSelector((state) => state.Auth.token);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [Record, setRecord] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      companyId: userData?.companyId,
      id: userData?._id
    };
    dispatch(Get_Brands_By_Id(data, usertoken));
  }, []);

  const showModal = (isEdited) => {
    if (!isEdited) {
      form.resetFields();
      setIsEdit(null);
    }
    setIsEdit(isEdited);
    setOpen(true);
  };

  const afterSuccess = () => {
    form.resetFields();
    setOpen(false);
  };

  const afterAdded = () => {
    const data = {
      companyId: userData?.companyId,
      id: userData?._id
    };
    dispatch(Get_Brands_By_Id(data, usertoken));
  };

  const onFinish = (values) => {
    if (isEdit) {
      const data = {
        brandDomain: values.brandDomain,
        brandname: values.brandName,
        _id: Record?._id,
        admin_id: userData?._id,
        companyId: userData?.companyId
      };
      dispatch(Update_Brand(data, usertoken, afterSuccess, afterAdded));
    }
    else {
      const data = {
        brandDomain: values.brandDomain,
        brandname: values.brandName,
        admin_id: userData?._id,
        companyId: userData?.companyId
      };
      dispatch(Add_Brand(data, usertoken, afterSuccess, afterAdded));
    }
  };

  const onClickRow = (data) => {
    if (userData?.role === "superadmin" || userData?.role === "admin") {
      setRecord(data);
      form.setFieldsValue({
        brandDomain: data?.brandDomain,
        brandName: data?.brandname
      });
      showModal(true);
    }
  };

  const onStatusChange = (data) => {
    const newdata = {
      admin_id: userData?._id,
      brand_id: data?._id,
      isActive: !data?.isActive
    };

    dispatch(Brand_Status(newdata, usertoken, afterAdded));

  };

  const handleCancel = () => {
    setOpen(false);
  };

  const adminsColumns = [
    {
      title: "Sr #",
      dataIndex: "serial",
    },
    {
      title: "Brand Name",
      dataIndex: "brandname",
    },
    {
      title: "Brand Domain",
      dataIndex: "brandDomain",
    },
    {
      title: "Token",
      dataIndex: "token"
    },
    {
      title: "Status",
      dataIndex: "isActive"
    }
  ];

  const columns = [
    {
      title: "Sr #",
      dataIndex: "serial",
    },
    {
      title: "Brand Name",
      dataIndex: "brandname",
    },
    {
      title: "Brand Domain",
      dataIndex: "brandDomain",
    },
    {
      title: "Token",
      dataIndex: "token"
    }
  ];

  const data = [];

  for (let i = 0; i < allData?.length; i++) {
    data.push({
      key: i,
      serial: i + 1,
      _id: allData[i]?._id,
      brandname: allData[i]?.brandname,
      token: allData[i]?.token,
      brandDomain: allData[i]?.brandDomain,
      isActive: <Switch checked={allData[i]?.isActive} onChange={() => onStatusChange(allData[i])} />
    });
  };

  return (
    <div>
      <div className="topBtns">
        <div>
        <Recoder />
        </div>
        <div>
          {userData?.role === "superadmin" || userData?.role === "admin" ?
            <Button type="primary" onClick={() => showModal(false)}>
              Add Brand
            </Button>
            : null
          }
        </div>

        {/* Modal Start */}
        <Modal
          title={isEdit ? "Edit Brand" : "Add Brand"}
          open={open}
          onCancel={handleCancel}
          footer={null}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            autoComplete="off">
            <Form.Item
              label="Brand Name :"
              name="brandName"
              rules={[
                {
                  required: true,
                  message: "Please input brand name!"
                }
              ]}>
              <Input placeholder="Brand Name" />
            </Form.Item>

            <Form.Item
              label="Brand Domain :"
              name="brandDomain"
              rules={[
                {
                  required: true,
                  message: "Please input brand domain!"
                }
              ]}>
              <Input placeholder="eg:xyz.com" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isAddLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {/* Modal End */}
      </div>
      <Table
        loading={isLoading}
        columns={userData?.role === "admin" ? adminsColumns : columns}
        dataSource={data}
        onRow={(data, rowIndex) => {
          return {

            onClick: (event) => onClickRow(data),
          };
        }}
        pagination={false}
      />
    </div>
  );
};

export default Brands;
